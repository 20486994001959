// Constantes
import { ADDONS } from '@/constants'
import { DISTANCE_UNIT_DELIVERY_AREAS } from '../../constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Utils
import { get, cloneDeep } from 'lodash'

export default {
  name: 'TakeAwayConfigAreas',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons,
    VuetifyToolBar
  },
  mixins: [formMixin, uiMixin, validationMixin, addonsMixin],
  data() {
    return {
      // Form
      formFields: {
        avoidOutboundOrders: true,
        distanceUnitDeliveryAreas: DISTANCE_UNIT_DELIVERY_AREAS.kilometers.value,
        deliveryAreas: []
      },
      formFieldsValidations: {
        avoidOutboundOrders: {
          required: 'Campo obligatorio'
        },
        distanceUnitDeliveryAreas: {
          required: 'Campo obligatorio'
        },
        deliveryAreas: {
          price: {
            required: 'Campo obligatorio'
          },
          distanceRadius: {
            required: 'Campo obligatorio'
          }
        }
      },
      // Others
      defaultModelDeliveryRadius: {
        price: null,
        distanceRadius: null
      },
      unitsDeliveryRadio: Object.values(DISTANCE_UNIT_DELIVERY_AREAS)
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * Añadimos un nuevo radio de acción
     */
    handleAddRadius() {
      this.formFields.deliveryAreas.push({
        ...this.defaultModelDeliveryRadius
      })
    },
    /**
     * Eliminamos un radio de acción existente
     *
     * @param {Number} index - indice a eliminar del array
     */
    handleDelRadius(index) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          this.formFields.deliveryAreas.splice(index, 1)
        },
        actionButtonText: 'Borrar',
        text: '¿Esta seguro que desea eliminar la zona de reparto?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Establece los valores inciales del formulario
     */
    getEveryNeededData() {
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const takeAwayConfig = get(this.placeAddonsSetupByUser, ADDONS.takeAway, {})

      this.formFields = {
        avoidOutboundOrders: get(takeAwayConfig, 'avoidOutboundOrders', true),
        deliveryAreas: cloneDeep(takeAwayConfig.deliveryAreas) || [],
        distanceUnitDeliveryAreas:
          takeAwayConfig.distanceUnitDeliveryAreas || DISTANCE_UNIT_DELIVERY_AREAS.kilometers.value
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.placeData.id,
        addOns: [
          {
            id: ADDONS.takeAway,
            configFields: this.formFields
          }
        ]
      })
      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations: {
    formFields: {
      avoidOutboundOrders: {
        required
      },
      deliveryAreas: {
        required,
        minLength: minLength(1),
        $each: {
          price: {
            required
          },
          distanceRadius: {
            required
          }
        }
      },
      distanceUnitDeliveryAreas: {
        required
      }
    }
  }
}
